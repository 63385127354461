import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Infonub from "../components/infonub"
import MarkContent from "../components/markContent"
import ColorContent from "../components/colorContent"
import PictureContent from "../components/pictureContent"
import Banner from "../components/banner"
import LowerBanner from "../components/lowerbanner"
export default ({ location }) => ( 
  <Layout location={location}>
  <main>

<Banner buttonText="contact us" pageLink="/contact" 
classy="bannerContent" 
image="/images/rewards-banner.png" 
alt="A man looking at this phone" 
content={[<p>Connect with real time recognition...any time, any device, any location.</p>]}/>
  
    
    
    <div className="divider blue"> </div>
    <article>
    
<MarkContent classy="marketingContent"  content={[<span className="bold">Engage employees </span>,  
"with our patented, award-winning technology. Reward and recognize achievements with our robust portfolio of solutions."]}/>

    
    

    <ColorContent classy="marketingContent fiveConnection" 
    content={[<p className="modern">Our modern, responsive, and user-friendly interface gives you instant access
    to game-changing rewards and recognition.</p>, 
    
    <p>We offer options that include HRIS Integration, single sign on, custom configuration, branding, tracking and measurement.</p>]}
    />


   <div class="infonub inLine">
     <Infonub picture="/images/graph-paper-icon.png" 
     alttext="legal paper" 
     title="Data-driven Solutions"
    content="65% of employees would work harder if they were recognized by their employers."
     />

    
    <Infonub picture="/images/trophy-icon.png" 
     alttext="a red trophy" 
     title="Robust Rewards"
    content="With the largest offering of rewards options in the market, there’s something for everyone: merchandise, physical and digital gift cards, Gift Tokens®, travel, experiences, branded Apple Store, and Premiere  Choice Awards. "/>

    
    <Infonub picture="/images/paper-icon.png" 
     alttext="A legal document" 
     title="Reporting Dashboard"
    content="Provides an interactive, dynamic way of looking at data. Generate user-friendly reports for valuable insights into how your program is performing."/>
    </div>
    
    <article className="event">
    
    <PictureContent picture="/images/way-to-go.jpg" 
    color="Blue"
  alttext="a congratulations letter"
  content={[<div className="divider blue"> </div>,<p>Add unique value to performance milestones  
    and appreciation with personalized Premiere 
    Choice Awards. These physical or digital  
    awards allow the recipient to select their 
  own gift card online.</p>]}/>

<PictureContent picture="/gifs/TrophyAnimation_Points.gif" 
color="Blue"
  alttext="a congratulations letter"
  content={[<div className="divider blue"> </div>,<p>Our communication and reward solution 
    solves for every level of engagement:  
    company to employee, employer to 
    employee, and peer to peer. </p>]}/>

    
</article>

     </article>
     
     <LowerBanner buttonText="shop now" 
     classy="lowerbannerContent" 
     image="/images/lower-banner@2x.jpg" 
      textSize="lowerBanner" 
      alt="Two people looking at a computer" 
      content={[<p className="bannerTitle"><strong>Online Shop</strong></p>,
      <p>The InComm Incentives Shop is our simplest solution for purchasing gift cards, physical or digital, in bulk. Our Shop lets you select from a large offering of gift card brands and purchase them in only a few steps. Discount options are also available on select brands.

      </p>,

      <p>No contracts, no minimums, no IT involvement.</p>
     
      
      ]}/>

  </main>
  
  </Layout>
)

